












































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      error: false as boolean | number,
      items: [],
      headers: [
        {
          value: "index",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        {
          value: "lecturer",
          text: root.$tc("layout.misc.lecturer"),
          align: "left",
        },
        {
          value: "isEnabled",
          text: root.$tc("layout.misc.state"),
          align: "right",
          width: 1,
        },
        {
          value: "actions",
          text: "",
          align: "right",
          width: 1,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/lecturer-website`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
          },
        })
        .then(({ data }) => {
          state.items = data.map((el: any) => ({
            id: el.id,
            isEnabled: el.isEnabled,
            firstName: el.participant
              ? el.participant.firstName
              : el.lecturer.firstName,
            lastName: el.participant
              ? el.participant.lastName
              : el.lecturer.lastName,
          }));
          state.total = data.length;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc(
            "event.panel.website.config.lecturers.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };
    const changeStatus = async (item: any, action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isEnabled: action === "off" ? false : true,
        additionalInformation: item.additionalInformation,
      };

      state.loading = true;

      axiosInstance
        .put(`/lecturer-website/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.saveSuccess"),
          });
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`lecturer-website/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { state, changeStatus, deleteItem, rules };
  },
});
