var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"item-key":"id","disable-sort":"","server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.lecturer",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.lastName)+" "+_vm._s(item.firstName))]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.event.website.lecturers.edit', params: { lid: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),(item.isEnabled)?_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(item, 'off')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-toggle-switch-off-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOff')))])],1):_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-toggle-switch-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOn')))])],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-list-item',_vm._g({},Object.assign({}, dialog)),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }